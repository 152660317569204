import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["messages", "form", "input"]

  connect() {
    this.scrollToBottom()
  }

  scrollToBottom() {
    if (this.hasMessagesTarget) {
      this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight
    }
  }

  submit(event) {
    const message = this.inputTarget.value.trim()
    if (message) {
      this.addMessageToChat('You', message)
      setTimeout(() => {
        this.inputTarget.value = ''
        this.inputTarget.focus()
      }, 0)
    } else {
      event.preventDefault()
    }
  }

  addMessageToChat(sender, content) {
    const messageDiv = document.createElement('div')
    messageDiv.className = 'text-right'
    messageDiv.innerHTML = `
      <div class="inline-flex items-start space-x-2 flex-row-reverse">
        <div class="inline-block max-w-xs md:max-w-md rounded-2xl px-4 py-2 bg-blue-500 text-white">
          ${content}
        </div>
      </div>
    `
    this.messagesTarget.appendChild(messageDiv)
    this.scrollToBottom()
  }

  handleKeydown(event) {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      this.formTarget.requestSubmit()
    }
  }

}